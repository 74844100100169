<template>
  <div class="frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="page-content">
      <header class="home">
        <b-container>
          <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <router-link
              :to="'/appointments'"
              class="brand-logo"
            >
              <img :src="logoImg">
            </router-link>
            <!-- /Brand logo-->
          </b-row>
        </b-container>
      </header>

      <div
        class="banner"
        :style="'background-image: url('+bannerImg+');'"
      >
        <div class="banner-content">
          <b-container>
            <b-row>
              <b-col>
                <h2>Death is the one inevitability that we must all face. But yet, it is something that few prepare for.</h2>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>

      <div
        v-show="appointmentBooking"
        v-if="!isMobile()"
        class="activities"
      >
        <div
          v-if="events.length"
          class="bg"
        />
        <b-container>
          <p class="service-title">
            Appointment Booking
          </p>
          <b-row class="gap-class">
            <b-card
              v-for="(activity, key) in activityTypes"
              :key="key"
              :img-src="resolveActivityImageSrc(key, activity.customFields)"
              img-alt="Image"
              img-bottom
            >
              <b-card-text class="text-center">
                <b-badge
                  v-if="activity.prospectBookable == true"
                  variant="danger"
                  style="background: #FAE3E6;
                  border-radius: 4px; padding: 5px 10px; font-size: 12px; font-weight: 400;
                  line-height: 18px; color: #D91B35; position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  top: -15px;"
                >
                  For Existing Customers
                </b-badge>
                <h3>{{ activity.typeName }}</h3>
                <h4>{{ activity.typeNameSG }}</h4>
                <b-button
                  :to="{ name: 'activity-booking-frontend', params: { id: activity._id }}"
                  variant="primary"
                >
                  Book now 马上预约
                </b-button>
              </b-card-text>
            </b-card>

            <!-- <b-card
              :img-src="activity4"
              img-alt="Image"
              img-bottom
            >
              <b-card-text class="text-center">
                <h3>Events</h3>
                <h4>活动</h4>
                <router-link
                  :to="'/upcoming-events'"
                  class="btn btn-primary"
                  :disabled="!events.length"
                >
                  View events 查看活动
                </router-link>
              </b-card-text>
            </b-card> -->
          </b-row>
        </b-container>
      </div>
      <div
        v-show="appointmentBooking"
        v-if="isMobile()"
        class="activities"
      >
        <div
          v-if="events.length"
          class="bg"
        />
        <b-container>
          <p class="service-title ">
            Appointment Booking
          </p>
          <b-row>
            <b-col
              v-for="(activity, key) in activityTypes"
              :key="key"
              md="6"
              lg="3"
            >
              <b-card
                :img-src="resolveActivityImageSrc(key, activity.customFields)"
                img-alt="Image"
                img-left
              >
                <b-card-text class="text-left">
                  <b-badge
                    v-if="activity.prospectBookable == true"
                    variant="danger"
                    style="background: #FAE3E6;
                    border-radius: 4px; padding: 5px 10px; font-size: 12px; font-weight: 400;
                    line-height: 18px; color: #D91B35; position: relative;"
                  >
                    For Existing Customers
                  </b-badge>
                  <h3>{{ activity.typeName }}</h3>
                  <h4>{{ activity.typeNameSG }}</h4>
                  <b-button
                    :to="{ name: 'activity-booking-frontend', params: { id: activity._id }}"
                    variant="primary"
                  >
                    Book now 马上预约
                  </b-button>
                </b-card-text>
              </b-card>
            </b-col>

            <!-- <b-col
              md="6"
              lg="3"
            >
              <b-card
                :img-src="activity4"
                img-alt="Image"
                img-left
              >
                <b-card-text class="text-left">
                  <h3>Events</h3>
                  <h4>活动</h4>
                  <router-link
                    :to="'/upcoming-events'"
                    class="btn btn-primary"
                    :disabled="!events.length"
                  >
                    View events 查看活动
                  </router-link>
                </b-card-text>
              </b-card>
            </b-col> -->
          </b-row>
        </b-container>
      </div>

      <div
        v-show="serviceBooking"
        v-if="!isMobile()"
        class="activities"
      >
        <div
          v-if="events.length"
          class="bg"
        />
        <b-container>
          <p class="service-title ">
            Service Booking
          </p>
          <b-row>
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                style="width: 100%;"
                :img-src="activity1"
                img-alt="Image"
                img-bottom
              >
                <b-card-text class="text-center">
                  <h3>Service Form</h3>
                  <h4>礼仪服务预约表格</h4>
                  <b-button
                    :to="{ name: 'service-form-booking-frontend' }"
                    variant="primary"
                  >
                    Book now 马上预约
                  </b-button>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div
        v-show="serviceBooking"
        v-if="isMobile()"
        class="activities"
      >
        <div
          v-if="events.length"
          class="bg"
        />
        <b-container>
          <p class="service-title ">
            Service Booking
          </p>
          <b-row>
            <b-col
              md="6"
              lg="3"
            >
              <b-card
                :img-src="activity1"
                img-alt="Image"
                img-left
              >
                <b-card-text class="text-left">
                  <h3>Service Form</h3>
                  <h4>礼仪服务预约表格</h4>
                  <b-button
                    :to="{ name: 'service-form-booking-frontend' }"
                    variant="primary"
                  >
                    Book now 马上预约
                  </b-button>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div v-if="showServiceBooking">
        <b-container>
          <b-row>
            <b-col
              md="6"
              lg="4"
            >
              <b-button
                v-if="appointmentBooking"
                variant="light"
                class="text-primary"
                @click="linkToServiceBooking"
              >
                Link to Service Booking
              </b-button>
              <b-button
                v-if="serviceBooking"
                variant="light"
                class="text-primary"
                @click="linkToAppointmentBooking"
              >
                Link to Appointment Booking
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div
        v-if="events.length"
        class="events front-event-min-height"
      >
        <b-container>
          <b-row>
            <b-col>
              <div class="section_title">
                <h1>Upcoming Events</h1>
                <router-link
                  :to="'/upcoming-events'"
                  class="btn btn-link"
                >
                  View all events 查看所有活动
                </router-link>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="! isMobile()">
            <b-col
              v-for="(event, index) in events"
              :key="index"
              md="4"
            >
              <div class="card">
                <div class="card-img">
                  <router-link
                    :to="{ name: 'event-detail-frontend', params: { slug: event.slug }}"
                  >
                    <img
                      :src="event.imageURLs ? (event.imageURLs.length ? event.imageURLs[0] : '') : ''"
                      class="card-img-top"
                    >
                  </router-link>
                </div>
                <div class="card-body">
                  <h3>
                    <router-link :to="{ name: 'event-detail-frontend', params: { slug: event.slug }}">
                      {{ event.name }}<br>{{ event.nameSG }}
                    </router-link>
                  </h3>
                  <ul class="event_info">
                    <li class="event_date">
                      {{ event.dateLabel }}<br>{{ event.dateLabelSG }}
                    </li>
                    <li class="event_location">
                      {{ event.location }} <br>{{ event.locationSG }}
                    </li>
                  </ul>

                  <ul class="tags">
                    <li
                      v-for="(tag, key) in event.tags"
                      :key="key"
                      class="tag tag_1"
                    >
                      {{ tag }}
                    </li>
                  </ul>

                  <p class="line-breaks-textarea">
                    {{ event.description }}
                  </p>

                  <ul class="button_list">
                    <li>
                      <router-link
                        :to="{ name: 'event-detail-frontend', params: { slug: event.slug } }"
                        class="btn btn-link"
                      >
                        Read more 阅读更多
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        v-if="event.packages.length"
                        :to="{ name: 'event-detail-frontend', params: { slug: event.slug }, query: { booking: true } }"
                        class="btn btn-link"
                      >
                        Book now 马上预约
                      </router-link>
                      <router-link
                        v-else
                        :to="{ name: 'event-booking-frontend', params: { slug: event.slug || 0 } }"
                        class="btn btn-link"
                      >
                        Book now 马上预约
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div
          v-if="isMobile()"
          class="hr_scroll"
        >
          <horizontal-scroll>
            <div class="outer">
              <div
                v-for="(event, index) in events"
                :key="index"
                class="event_item"
              >
                <div class="card">
                  <div class="card-img">
                    <router-link
                      :to="{ name: 'event-detail-frontend', params: { slug: event.slug }}"
                    >
                      <img
                        :src="event.imageURLs ? (event.imageURLs.length ? event.imageURLs[0] : '') : ''"
                        class="card-img-top"
                      >
                    </router-link>
                  </div>
                  <div class="card-body">
                    <h3>
                      <router-link :to="{ name: 'event-detail-frontend', params: { slug: event.slug }}">
                        {{ event.name }}<br>{{ event.nameSG }}
                      </router-link>
                    </h3>
                    <ul class="event_info">
                      <li class="event_date">
                        {{ event.dateLabel }}<br>{{ event.dateLabelSG }}
                      </li>
                      <li class="event_location">
                        {{ event.location }} <br>{{ event.locationSG }}
                      </li>
                    </ul>

                    <ul class="tags">
                      <li
                        v-for="(tag, key) in event.tags"
                        :key="key"
                        class="tag tag_1"
                      >
                        {{ tag }}
                      </li>
                    </ul>

                    <ul class="button_list">
                      <li>
                        <router-link
                          :to="{ name: 'event-detail-frontend', params: { slug: event.slug } }"
                          class="btn btn-link"
                        >
                          Read more 阅读更多
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          v-if="event.packages.length"
                          :to="{ name: 'event-detail-frontend', params: { slug: event.slug }, query: { booking: true } }"
                          class="btn btn-link"
                        >
                          Book now 马上预约
                        </router-link>
                        <router-link
                          v-else
                          :to="{ name: 'event-booking-frontend', params: { slug: event.slug || 0 } }"
                          class="btn btn-link"
                        >
                          Book now 马上预约
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </horizontal-scroll>
        </div>
      </div>

      <footer
        :class="{'extra-padding': !events.length}"
      >
        <b-container>
          <b-row>
            <b-col>
              <p>© {{ new Date().getFullYear() }} Nirvana Memorial Garden Pte. Ltd.</p>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </div>

  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BContainer, BCol, BCardText, BCard, BButton, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import Loader from './Loader.vue'

export default {
  components: {
    BRow,
    BContainer,
    BCol,
    BCardText,
    BCard,
    BButton,
    HorizontalScroll,

    Loader,
    BBadge,
  },
  data() {
    return {
      events: [],
      activityTypes: [],
      logoImg: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      bannerImg: require('@/assets/images/frontend/banner_image.png'),
      activity1: require('@/assets/images/frontend/activity1.png'),
      activity4: require('@/assets/images/frontend/activity4.png'),
      serviceBooking: false,
      appointmentBooking: true,
      showServiceBooking: false,
    }
  },
  beforeMount() {
    this.$http.get('front-end/events/upcoming-events')
      .then(response => {
        this.events = response.data.events || []
        this.activityTypes = response.data.activityTypes || []
        if (response.data.showServiceBooking) {
          this.showServiceBooking = true
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveActivityImageSrc(key, fields) {
      let imgSrc = ''
      if (this.activityTypes[key].imageURLs.length) {
        // eslint-disable-next-line prefer-destructuring
        imgSrc = this.activityTypes[key].imageURLs[0]
      } else {
        const obj = fields.find(o => o.type === 'file')

        if (obj) {
          imgSrc = obj.file
        }
      }

      return imgSrc
    },
    linkToServiceBooking() {
      this.serviceBooking = true
      this.appointmentBooking = false
    },
    linkToAppointmentBooking() {
      this.serviceBooking = false
      this.appointmentBooking = true
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px) and (max-width:991px) {
  .page-content .activities .card {
    width: calc(50% - 15px) !important;
  }
  .gap-class {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px){
  .page-content .activities .card {
    width: calc(25% - 23px);
  }
  .gap-class {
    gap: 30px;
    padding: 0 15px;
  }
  .page-content .activities .card img {
    width: 100%;
    height: 160px;
    border-radius: 0 0 8px 8px;
    object-fit: cover;
  }
}
.page-content .btn-light:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #fffdfd;
}
.page-content .btn-light {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
.page-content .btn-light:hover {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
.page-content p.service-title {
  padding: 0;
  margin: 0 0 15px !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
</style>
